.explanation-parent {
  font-family: Arial, sans-serif;
  margin: 20px;
  text-align: start;

  h2 {
    color: #3f7bbc;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    color: #dddddd;
  }

  li {
    margin-bottom: 10px;
  }

  p {

  }
}
