@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'react-dropdown/style.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ai-use-status {
  &.yes {
    background: #5a8cd4;
  }

  &.no {
    background: #2d9a68;
  }
}

.version-details {
  position: absolute;
  bottom: 2px;
  right: 10px;
  color: gray;
  user-select: none;
}
